import styled from 'styled-components';

const IconWrapper = styled.div`
  height: 28px;
  width: 28px;
  background-image: url(${({ icon }) => icon});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
`;

export default IconWrapper;

import React from 'react';
import { Title, Text, SectionWrapper } from 'components';
import Macbook from 'assets/images/macbook.png';
import Fade from 'react-reveal/Fade';

const Explanation = () => {
  return (
    <SectionWrapper className="container" id="about">
      <div className="row flex-column-reverse flex-lg-row">
        <div className="col-lg-4 d-flex flex-column justify-content-center">
          <Title className="mb-4">Kodėl VaTaip?</Title>
          <Text>
            Nustokite švaistyti savo laiką vesdami tuos pačius apmokymus naujokams! Su VaTaip programėle, pravesk
            mokymus, įvertink esamų darbuotojų kvalifikaciją, bei paskelbk aktualiausia informaciją.
          </Text>
        </div>
        <div className="col-lg-8 mb-5 md-lg-0">
          <Fade bottom>
            <img src={Macbook} alt="" className="img-fluid" />
          </Fade>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default Explanation;

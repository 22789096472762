import styled from 'styled-components';

const Title = styled.h2`
  font-size: 28px;
  color: #414141;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
`;

export default Title;

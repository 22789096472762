import React from 'react';
import styled from 'styled-components';

const ButtonWrapper = styled.button`
  border-radius: 30px;
  border: solid 1px #8a8a8a;
  padding: 5px 20px;
  outline: none;
  position: relative;
  background: none;
  -webkit-appearance: none;
  appearance: none;
  z-index: 1;
  font-family: Roboto;
  font-weight: 300;
  line-height: 1.31;
  color: #8a8a8a;
  padding: ${({ padding }) => padding};
`;

const Button = ({ children, ...props }) => {
  return <ButtonWrapper {...props}>{children}</ButtonWrapper>;
};

export default Button;

import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Hero, Explanation, DemoForm, Info, RegisterForm } from 'features';
import { Header } from 'components';
import { createGlobalStyle } from 'styled-components';
import './index.scss';

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
  
  body {
    font-family: 'Roboto', sans-serif !important;
    /* background-color: #DBDBDB !important; */
    background: rgb(250,250,250);
background: radial-gradient(circle, rgba(250,250,250,1) 16%, rgba(210,210,210,1) 100%);
  }
`;

const App = () => {
  return (
    <Fragment>
      <GlobalStyles />
      <Header />
      <Hero />
      <Explanation />
      <DemoForm />
      <Info />
      <RegisterForm />
    </Fragment>
  );
};

ReactDOM.render(<App />, document.querySelector('#root'));

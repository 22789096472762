import React from 'react';
import styled from 'styled-components';
import PrimaryHero from './components/PrimaryHero';
import SecondaryHero from './components/SecondaryHero';
import { SectionWrapper } from 'components';
import Phone from 'assets/images/app.png';

const HeroWrapper = styled(SectionWrapper)`
  padding-top: 200px;
`;

const AppPreview = styled.img`
  max-width: 300px;
  position: -webkit-sticky;
  position: sticky;
  top: 200px;
  @media screen and (max-width: 991px) {
    position: relative;
    top: 0;
  }
`;

const Hero = () => {
  return (
    <HeroWrapper className="container" id="hero">
      <div className="row">
        <div className="col-lg-6 d-none d-lg-block text-center">
          <AppPreview src={Phone} />
        </div>
        <div className="col-lg-6">
          <PrimaryHero className="mb-5" />
          <div className="d-lg-none text-center">
            <AppPreview src={Phone} className="mb-5" />
          </div>
          <SecondaryHero />
        </div>
      </div>
    </HeroWrapper>
  );
};

export default Hero;

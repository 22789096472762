import React from 'react';
import styled from 'styled-components';
import { Title, Text, Icon } from 'components';
import Burger from 'assets/images/burger.svg';
// import User from 'assets/images/user.svg';
// import Users from 'assets/images/users.svg';
// import Chat from 'assets/images/chat.svg';
// import Search from 'assets/images/search.svg';
import Fade from 'react-reveal/Fade';

const BulletPoint = styled.div`
  font-size: 18px;
  line-height: 1.32;
  color: #707070;
`;

const ContentWrapper = styled.div`
  margin: ${({ margin }) => margin};
`;

const Content = ({ direction, margin, title, text, list = [], ...props }) => {
  return (
    <ContentWrapper margin={margin} {...props}>
      <Fade left={direction === 'left'} right={direction === 'right'}>
        <Title margin="0 0 50px 0">{title}</Title>
        <Text margin="0 0 70px 0">{text}</Text>
      </Fade>
      <Fade left={direction === 'left'} right={direction === 'right'} cascade>
        <div className="row">
          {list.map((item, index) => (
            <BulletPoint className="col-md-6 d-flex align-items-center mb-4" key={index}>
              <Icon icon={Burger} className="mr-3" />
              {item}
            </BulletPoint>
          ))}
        </div>
      </Fade>
    </ContentWrapper>
  );
};

export default Content;

import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Icon, Title, Text } from 'components';
import Worker from 'assets/images/worker.svg';
import Roles from 'assets/images/roles.svg';
import Info from 'assets/images/info.svg';
import Chat from 'assets/images/chat.svg';
import Email from 'assets/images/email.svg';
import Fade from 'react-reveal/Fade';

const Feature = styled.li`
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.32;
  color: #707070;
`;

const SecondaryHero = () => {
  return (
    <Fragment>
      <Fade bottom>
        <Title className="mb-4">Kas tai ?</Title>
        <Text className="mb-5">
          VaTaip yra nuotolinių mokymų sistema, padėsianti kvalifikuoti bet kokio tipo aptarnaujantį personalą, bet
          kuriame pasaulio gale.
        </Text>
      </Fade>

      <Fade right cascade big>
        <ul className="p-0 m-0 list-group">
          <Feature>
            <Icon className="mr-3" icon={Worker} /> Stebėkite darbuotojų progresą
          </Feature>
          <Feature>
            <Icon className="mr-3" icon={Roles} /> Paskirkite roles
          </Feature>
          <Feature>
            <Icon className="mr-3" icon={Info} /> Laikykite visą reikiamą informaciją vienoje vietoje
          </Feature>
          <Feature>
            <Icon className="mr-3" icon={Chat} /> Bendravimas aplikacijos viduje
          </Feature>
          <Feature>
            <Icon className="mr-3" icon={Email} /> Skelbkite visas aktualiausias naujienas
          </Feature>
        </ul>
      </Fade>
    </Fragment>
  );
};

export default SecondaryHero;

import styled from 'styled-components';

const SectionWrapper = styled.section`
  margin-bottom: 200px;

  @media screen and (max-width: 991px) {
    margin-bottom: 100px;
  }
`;

export default SectionWrapper;

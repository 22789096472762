import styled from 'styled-components';

const Text = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.32;
  color: #707070;
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
`;

export default Text;

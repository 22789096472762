import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import Logo from 'assets/images/logo.svg';
import { Button } from 'components';
import { useScrollYPosition } from 'react-use-scroll-position';
import { stack as Menu } from 'react-burger-menu';
import BurgerImg from 'assets/images/burger.svg';

const styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    left: '36px',
    top: '36px',
  },
  bmBurgerBars: {
    background: '#373a47',
  },
  bmBurgerBarsHover: {
    background: '#a90000',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: '#bdc3c7',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    left: 0,
    zIndex: 11111,
    top: 0,
  },
  bmMenu: {
    background: '#373a47',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em',
    display: 'flex',
    flexDirection: 'column',
  },
  bmItem: {
    display: 'inline-block',
    marginBottom: 20,
    cursor: 'pointer',
  },
  bmOverlay: {
    top: 0,
    left: 0,
    zIndex: 1111,
    background: 'rgba(0, 0, 0, 0.3)',
  },
};

const LogoWrapper = styled.img`
  transition: all 0.2s linear;
  height: ${({ fixed }) => (fixed ? '35px' : '55px')} !important;
`;

const HeaderWrapper = styled.header`
  transition: background-color 0.2s linear;
  padding: 10px;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  margin: 0;
  z-index: 10;
  background-color: ${({ fixed }) => (fixed ? 'white' : '')};
`;

const Burger = styled.img`
  cursor: pointer;
`;

const HeaderNav = styled.ul`
  list-style: none;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  color: #8a8a8a;
  display: flex;
  margin: 0;
  padding: 0;

  li {
    margin-right: 20px;
    margin-bottom: ${({ mobile }) => (mobile ? '20px' : '0px')};
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      color: inherit;
    }
  }
`;

const HeaderMenu = ({ onClick, ...props }) => (
  <HeaderNav {...props}>
    <li>
      <a onClick={onClick} href="#hero">
        pradžia
      </a>
    </li>
    <li>
      <a onClick={onClick} href="#about">
        apie
      </a>
    </li>
    <li>
      <a onClick={onClick} href="#demo">
        demo
      </a>
    </li>
    <li>
      <a onClick={onClick} href="#register">
        registracija
      </a>
    </li>
  </HeaderNav>
);

const Header = ({ scroll }) => {
  const y = useScrollYPosition();
  const [isOpen, toggleOpen] = useState(false);
  const toggleMenuOpen = useCallback(() => {
    toggleOpen(!isOpen);
  }, [isOpen]);
  return (
    <HeaderWrapper fixed={y > 70}>
      <div className="container-fluid d-flex justify-content-between align-items-center p-0">
        <LogoWrapper src={Logo} className="img-fluid" alt="VaTaip logo" fixed={y > 70} />
        <HeaderMenu className="d-none d-lg-flex" onClick={toggleMenuOpen} />
        <Burger className="d-block d-lg-none" src={BurgerImg} alt="" onClick={toggleMenuOpen} />
      </div>

      <Menu isOpen={isOpen} styles={styles} disableAutoFocus customBurgerIcon={false}>
        <HeaderMenu mobile onClick={toggleMenuOpen} />
      </Menu>
    </HeaderWrapper>
  );
};

export default Header;

import React from 'react';
import styled from 'styled-components';
import { Button, Icon, Text } from 'components';
import Logo from 'assets/images/logo.svg';
import AndroidLogo from 'assets/images/android.svg';
import AppleLogo from 'assets/images/apple.svg';
import DesktopLogo from 'assets/images/desktop.svg';

const LogoWrapper = styled.div`
  background-image: url(${Logo});
  background-size: contain;
  width: 170px;
  height: 105px;
  margin-bottom: 40px;
  background-repeat: no-repeat;
`;

const IconWrapper = styled(Icon)`
  margin-right: 25px;
  &:nth-last-of-type() {
    margin: 0;
  }
`;

const HeroTitle = styled.h1`
  font-size: 28px;
  color: #414141;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  margin-bottom: 15px;
`;

const HeroWrapper = styled.div`
  margin-bottom: 400px;

  @media screen and (max-width: 991px) {
    margin-bottom: 250px;
  }
`;

const PrimaryHero = ({ className }) => {
  return (
    <HeroWrapper>
      <LogoWrapper />
      <HeroTitle>Nauja nuotolinių mokymų sistema</HeroTitle>
      <Text className="mb-5">Viskas ko reikia aukščiausiai darbo kokybei pasiekti, patogiausiu laiku ir vietoje.</Text>
      <div className="d-flex justify-content-start mb-5">
        <Button className="mr-3">Pradėk naudotis dabar!</Button>
        <Button>Kaip tai veikia?</Button>
      </div>
      <div className="d-flex justify-content-center">
        <IconWrapper icon={AppleLogo} />
        <IconWrapper icon={AndroidLogo} />
        <IconWrapper icon={DesktopLogo} />
      </div>
      {/* <div>
        <ScrollDownWrapper icon={SlideDown} />
      </div> */}
    </HeroWrapper>
  );
};

export default PrimaryHero;

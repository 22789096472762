import React from 'react';
import styled from 'styled-components';
import { getIn } from 'formik';

export const InputWrapper = styled.input`
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  color: #414656;
  display: block;
  border: 1px solid ${({ error }) => (error ? 'red' : '#8a8a8a')};
  transition: border 0.4s, color 0.4s;
  font-size: 12px;
  border-radius: 20px;
  background: #fff;
  padding: 0 20px;
  height: 40px;
  width: 100%;
  background-color: transparent;
  max-width: 512px;
  margin: 0 auto;

  :hover,
  :focus {
    border: 1px solid ${({ error }) => (error ? 'red' : '#b1b5c2')};
  }
`;

const FormikInput = ({ field, form, ...props }) => {
  const { touched, errors, setFieldValue } = form;
  const { placeholder, type = 'text', onChange, ...rest } = props;
  const error = getIn(errors, field.name) && getIn(touched, field.name);
  return (
    <div>
      <InputWrapper
        {...field}
        type={type}
        error={error}
        placeholder={placeholder}
        {...rest}
        onChange={e => {
          if (onChange) onChange(e, form);
          setFieldValue(field.name, e.target.value);
        }}
      />
    </div>
  );
};

export default FormikInput;

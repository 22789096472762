import React from 'react';
import styled from 'styled-components';
import Content from './components/Content';
import { SectionWrapper } from 'components';
import PhoneMultiple from 'assets/images/multiple.png';
import Samsung from 'assets/images/samsung.png';
import Fade from 'react-reveal/Fade';
import { useWindowSize } from 'utils';

const InfoSection = styled.section`
  margin-bottom: 200px;

  @media screen and (max-width: 991px) {
    margin-bottom: 100px;
  }
`;

const SamsungWrapper = styled.img`
  width: 45%;
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
`;

const Info = () => {
  const { innerWidth } = useWindowSize();
  return (
    <SectionWrapper>
      <div className="container">
        <InfoSection className="row">
          <div className="col-lg-6">
            <Content
              margin={`0 0  ${innerWidth > 991 ? '200' : '50'}px 0`}
              direction="left"
              title="Padidinkite darbo našumą"
              text="Nenutrauk savo ir kitų darbo ieškodamas teisingo atsakymo, visa reikiama informacija nuo šiol tavo išmaniajame telefone."
            />
            <Fade bottom>
              <div className="d-lg-none text-center mb-5">
                <SamsungWrapper src={Samsung} className="img-fluid" alt="" />
              </div>
            </Fade>
            <Content
              margin={`0 0  ${innerWidth > 991 ? '150' : '0'}px 0`}
              direction="left"
              title="Mažinkite apmokymų kainą"
              text="Bet kur ir bet kada pasiekiamos profesionalų instrukcijos, kiekvieno darbuotojo telefone. Stebėkite darbuotojų pažangą, padėkite užpildyti žinių spragas interaktyvių testų pagalba."
              list={['mokymosi medžiaga', 'Ekspertų pamokos', 'Testai', 'Egzaminai']}
            />
          </div>
          <Fade bottom>
            <div className="col-6 d-none d-lg-block text-right">
              <SamsungWrapper src={Samsung} className="img-fluid" alt="" />
            </div>
          </Fade>
        </InfoSection>
        {/* <div className="row">
          <div className="col-lg-6 mb-5 mb-lg-0">
            <Fade bottom>
              <img src={PhoneMultiple} className="img-fluid mb-5 mb-lg-0" alt="" />
            </Fade>
          </div>
          <div className="col-lg-6">
            <Content
              direction="right"
              title="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Architecto beatae quis voluptates. Quibusdam blanditiis molestiae aperiam, ut autem illo nihil ipsam debitis nam quam nulla totam inventore voluptate asperiores qui?"
              list={['Lorem ipsum dolor sit amet', 'Lorem ipsum dolor sit amet', 'Lorem ipsum dolor sit amet']}
            />
          </div>
        </div> */}
      </div>
    </SectionWrapper>
  );
};

export default Info;

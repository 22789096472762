import React from 'react';
import { FormikInput, Button, Title, SectionWrapper } from 'components';
import { Formik, Field } from 'formik';
import Fade from 'react-reveal/Fade';
import * as yup from 'yup';

const LoginValidation = yup.object().shape({
  name: yup.string().required(),
  phone: yup.string().required(),
  restaurant: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
  promoCode: yup.string().required()
});

const RegisterForm = () => {
  return (
    <SectionWrapper className="container" id="register">
      <Fade bottom>
        <div className="row">
          <div className="col-12 text-center">
            <Title margin="0 0 50px 0">Pradėkite naudotis Dabar</Title>
          </div>
          <div className="col-12 d-flex justify-content-center">
            <Formik
              initialValues={{ name: '', restaurant: '', phone: '', email: '', promoCode: '' }}
              validationSchema={LoginValidation}
              onSubmit={(values, actions) => {
                // const { state } = location;
                // const from = state && state.from.pathname;
              }}
              render={props => (
                <form onSubmit={props.handleSubmit} className="ml-0 ml-lg-5">
                  <div className="row">
                    <div className="col-12 mb-4">
                      <Field component={FormikInput} name="name" placeholder="Vardas" />
                    </div>
                    <div className="col-12 mb-4">
                      <Field component={FormikInput} name="restaurant" placeholder="Restoranas" />
                    </div>
                    <div className="col-12 mb-4">
                      <Field component={FormikInput} name="phone" placeholder="Tel. nr." />
                    </div>
                    <div className="col-12 mb-4">
                      <Field component={FormikInput} type="email" name="email" placeholder="El. paštas" />
                    </div>
                    <div className="col-12 mb-5">
                      <Field component={FormikInput} name="promoCode" placeholder="PROMO kodas" />
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                      <Button type="submit" padding="15px 30px">
                        Išbandyk nemokamai
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </Fade>
    </SectionWrapper>
  );
};

export default RegisterForm;
